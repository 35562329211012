<template>
    <div class="content page-style-proto">
        <v-row class="justify-space-between">
            <v-col>
                <v-row>
                    <v-col class="justify-start d-flex">
                        <h2 class="pr-6">Students</h2>
                        <v-menu open-on-hover offset-y v-if="canView">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        depressed
                                        class="text-capitalize font-weight-bold"
                                >
                                    <v-icon left>mdi-plus</v-icon>
                                    Add New
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item link @click="handleManualInputClick">
                                    <v-list-item-title>Manual Input</v-list-item-title>
                                </v-list-item>
                                <v-list-item link @click="handleBulkImportClick">
                                    <v-list-item-title>Bulk Import</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="primary"
                         depressed class="text-capitalize font-weight-bold"
                         @click="generateReport"
                  >Generate Report</v-btn>
                </template>
                <span>Generate Report based on filtered content. This will generate a csv report for all results without pagination</span>
              </v-tooltip>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <StudentDataTable />
            </v-col>
        </v-row>

        <!-- dialogs--->
        <v-dialog v-model="showStudentDialog" scrollable max-width="1111">
            <StudentPreview v-on:hideDialog="showStudentDialog=false"/>
        </v-dialog>
        <v-dialog v-if="showBulkImportDialog" v-model="showBulkImportDialog" scrollable max-width="540">
            <StudentBulkImport v-on:onHideDialog="showBulkImportDialog=false"/>
        </v-dialog>
    </div>
</template>

<script>
    import StudentDataTable from "../components/Students/StudentDataTable";
    import StudentBulkImport from "../components/Students/StudentBulkImport";
    import StudentPreview from "../components/Students/StudentPreview";
    import {displayMessage} from "../utils/utils";
    import StudentService from "@/services/student.service";

    export default {
        name: 'Students',
        components : {
            StudentPreview,
            StudentBulkImport,
            StudentDataTable,
        },
        data() {
            return {
                showStudentDialog: false,
                showBulkImportDialog: false
            }
        },
        computed: {
            user() {
                return this.$store.getters.getUser;
            },
            canView() {
              return this.user.type !== 'schoolContact'
            },
            activeFilterParams() {
              return this.$store.getters.getActiveFiltersParams(this.user)
            },
        },
        methods: {
            handleManualInputClick() {
                if (!this.$store.getters.userHasPermission('students', 'create')) {
                    displayMessage('error', 'Sorry you do not have permission to create students')
                    return
                }

                this.$store.commit('setIsAddNewStudentState', true);
                this.showStudentDialog = true;
            },
            handleBulkImportClick() {
                if (!this.$store.getters.userHasPermission('students', 'bulk_import')) {
                    displayMessage('error', 'Sorry you do not have permission to import students')
                    return
                }

                this.showBulkImportDialog = true
            },
            async generateReport() {
              const queryParams = new URLSearchParams(this.activeFilterParams || '');
              queryParams.append('sortBy', 'adminRepStartDate');
              let students = await StudentService.getStudents('?' + queryParams.toString());

              students = students.map(s => {
                return {
                  Name: s.name ?? 'N/A',
                  Email: s.email,
                  Age: s.age,
                  School: s.school.name,
                  Campus: s.campus.name,
                  "Admin Rep": s?.school_contact?.name || '',
                  "Start Date": s.university_start_date,
                  Status: s.status.keyword,
                  "Paid/Unpaid" : s.has_paid_application_fees ? 'PAID' : 'UNPAID'
                }
              })

              const csv = this.$papa.unparse(students)
              this.$papa.download(csv, 'student-report')
              this.$emit('setGenerateReportFalse')
            }
        },
        beforeMount() {
            //get the student statuses
            this.$store.dispatch('getStudentStatuses');
            this.$store.dispatch('getAmenities');
            this.$store.dispatch('getReferralStatuses');
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
