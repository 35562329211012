<template>
    <div>
        <v-row>
            <v-col cols="12" md="5">
                <v-text-field outlined dense append-icon="mdi-magnify" v-model="search" placeholder="Search All Agents" background-color="white"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense label="Schools"
                          :items="schools"
                          background-color="white"
                          v-model="schoolFilter"
                          item-text="name"
                          item-value="id"
                          @change="getAgents"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense label="State"
                          :items="states"
                          v-model="stateFilter"
                          item-text="name"
                          item-value="id"
                          background-color="white"
                          @change="getAgents"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2" class="justify-end d-flex">
                <v-btn text height="40" color="primary" @click="resetFilters">
                    Clear Filters
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :search="search"
            :headers="headers"
            :items="agents"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1 table-proto"
            @page-count="pageCount = $event"
            :loading="isLoadingAgents || isDeletingAgent"
        >
            <template v-slot:item.user.name="props">
                <a @click="showAgent(props.item)">{{props.item.user.name}}</a>
            </template>
<!--            <template v-slot:item.delete="props">-->
<!--                <v-avatar tile width="16" height="18" min-width="16" min-height="18" @click="showNotification(props.item.id)">-->
<!--                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"-->
<!--                           @mouseover="handleDeleteIconMouseOver(props.item.id)"-->
<!--                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"-->
<!--                    ></v-img>-->
<!--                </v-avatar>-->
<!--            </template>-->
            <template v-slot:item.nav="props">
                <v-avatar tile size="10" @click="showAgent(props.item)">
                    <v-img :src="require(`@/assets/icons/nav.svg`)"></v-img>
                </v-avatar>
            </template>
        </v-data-table>
        <v-row class="justify-end" v-if="!isLoadingAgents">
            <v-col cols="12" md="6" class="text-right">
                <v-row>
                    <v-col cols="12" md="1" class="pt-md-5">Show</v-col>
                    <v-col cols="12" md="2">
                        <v-select :items="pages" dense
                                  outlined
                                  @change="itemsPerPage = parseInt($event, 10)"
                                  v-model="itemsPerPage"
                                  background-color="white"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="1" class="pt-md-5 text-left">Entries</v-col>
                    <v-col cols="12" md="5">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog width="500" v-model="showNotificationDialog">
            <Notification message="You are about to delete this agent."
                    v-on:hideDialog="showNotificationDialog=false"
                          v-on:continue="deleteAgent"
            />
        </v-dialog>
        <v-dialog width="1000" v-model="showAgentDialog">
            <Agent :agent="currentAgent" v-on:hideDialog="showAgentDialog=false" is-dialog="true" source="agent"/>
        </v-dialog>
    </div>
</template>

<script>
    import Notification from "../Shared/Notification";
    import AgentService from "../../services/agent.service";
    import Agent from "./Agent";

    export default {
        name: 'AgentDataTable',
        components: {
            Agent,
            Notification,
        },
        data () {
            return {
                search: '',
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                schoolFilter: '',
                stateFilter: '',
                pages: [5, 10, 15, 20, 25, 30],
                isDeletingAgent: false,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'user.name',
                    },
                    { text: 'Username', value: 'username' },
                    { text: 'Role', value: 'role.role' },
                    // { text: '', value: 'delete' },
                    { text: '', value: 'nav' },
                ],
                showNotificationDialog: false,
                showAgentDialog: false,
                currentAgentId: null,
                currentAgent: {}
            }
        },
        computed: {
            agents() {
                return this.$store.getters.getAgents
            },
            states() {
                return this.$store.getters.getStates;
            },
            schools() {
                return this.$store.getters.getSchools;
            },
            isLoadingAgents() {
                return this.$store.getters.getIsLoadingAgents;
            }
        },
        methods: {
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            getAgents() {
                this.$store.dispatch('getAgents', '?sort=desc&appendCampuses&appendRole&appendUser');
            },
            deleteAgent: async function() {
                this.showNotificationDialog = false;
                this.isDeletingAgent = true;
                await AgentService.deleteAgent(this.currentAgentId)
                this.isDeletingAgent = false;

                this.getRentSpecials();
            },
            showNotification(agentId) {
                this.currentAgentId = agentId;
                this.showNotificationDialog = true;
            },
            resetFilters() {
                this.schoolFilter = '';
                this.stateFilter  = '';
                this.search       = '';

                this.getAgents();
            },
            showAgent(agent) {
                this.currentAgent = agent;
                this.showAgentDialog = true;
            }
        }
    }
</script>

<style scoped>
    .a-filter {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-decoration: none;
    }
</style>
